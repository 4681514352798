import React, { useState } from "react";
import { Button, Descriptions } from "antd";
import UserEditModal from "./UserEditModal";
import { useResourceContext } from "../../common/resource/useResourceContext";
import moment from "moment";
import { useAuthContext } from "../../auth/context/useAuthContext";
import { genderMapper } from "../../users/services/users.utils";
import { UserPhoneDeleteModal } from "./UserPhoneDeleteModal";

const UserDetailInfo = ({ userData, setForceReload }) => {
  const { auth, hasPermission } = useAuthContext();
  const { R } = useResourceContext()

  const [modalState, setModalState] = useState({
    isVisible: false,
    data: {}
  })
  const [phoneDeleteVisible, setPhoneDeleteVisible] = useState(false);


  if (!userData) return <>{R.text.loading}...</>

  return (
    <>
      <Descriptions column={1} labelStyle={{ width: "240px" }}>
        <Descriptions.Item>
          <div style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "4px",
            alignItems: "center",
            gap: "4px"
          }}>
            <p style={{
              fontSize: "14px",
              fontWeight: "700",
              margin: "0 8px 0 0"
            }}>
              {R.text.user_information}
            </p>
            <Button
              disabled={!auth || !hasPermission("ChangeUserButtonVisible")}
              onClick={() => setModalState({
                isVisible: true,
                data: userData
              })}
              type="default"
              size={"small"}>
              이메일 수정
            </Button>
            <Button
              danger
              disabled={!auth || !hasPermission("ChangeUserButtonVisible")}
              onClick={() => setPhoneDeleteVisible(true)}
              type="default"
              size={"small"}>
              번호 삭제
            </Button>
          </div>
        </Descriptions.Item>
        <Descriptions.Item label={`${R.text.phone}`}>
          {userData?.phoneNumber}
        </Descriptions.Item>
        <Descriptions.Item label={`${R.text.external_id}`}>
          {userData?.externalId}
        </Descriptions.Item>
        <Descriptions.Item label={`${R.text.name}`}>
          {userData?.name}
        </Descriptions.Item>
        <Descriptions.Item label={`${R.text.gender}`}>
          {userData.sex ? genderMapper(userData.sex) : "-"}
        </Descriptions.Item>
        <Descriptions.Item label={`${R.text.birthday}`}>
          {userData?.birthday}
        </Descriptions.Item>
        <Descriptions.Item label={`${R.text.signup_at}`}>
          {userData.joinAt ? moment.utc(userData.joinAt).local().format("YY-MM-DD HH:mm:ss") : "-"}
        </Descriptions.Item>
        <Descriptions.Item label={`${R.text.level}`}>
          {`${userData.grade ? userData.grade.grade : "-"}`}
        </Descriptions.Item>
        <Descriptions.Item label={`${R.text.grade_ride_count}`}>
          {`${userData.grade ? userData.grade.gradeRideCount : "-"}`}
        </Descriptions.Item>
        <Descriptions.Item label={`${R.text.ride_count}`}>
          {userData?.rideCount}
        </Descriptions.Item>
        <Descriptions.Item label={`${R.text.email}`}>
          {userData?.email}
        </Descriptions.Item>
      </Descriptions>
      <UserEditModal modalState={modalState} setModalState={setModalState} setForceReload={setForceReload} />
      <UserPhoneDeleteModal
        onOpenChange={(visible) => setPhoneDeleteVisible(visible)}
        open={phoneDeleteVisible}
        setForceReload={setForceReload}
        userData={userData} />
    </>
  )
}

export default UserDetailInfo;
