import React, {useCallback, useEffect} from "react"
import {Button, Card, Col, Form, Input, Modal, Row, Select} from 'antd';
import {updateUser} from "../../users/services/users.service";
import {useResourceContext} from "../../common/resource/useResourceContext";

const {Option} = Select;

const UserEditModal = ({modalState, setModalState, setForceReload}) => {
  const {R} = useResourceContext()

  const [form] = Form.useForm();

  const hideModal = useCallback(() => {
    setModalState({
      isVisible: false,
      data: {},
    });
  }, [setModalState]);

  function isEmptyObject(param) {
    return Object.keys(param).length === 0 && param.constructor === Object;
  }

  useEffect(() => {

    /** initialize form data */
    form.resetFields();

    form.setFields([
      {name: 'id', value: ''},
      {name: 'name', value: ''},
      {name: 'phoneNumber', value: ''},
      {name: 'externalId', value: ''},
      {name: 'sex', value: ''},
      {name: 'birthday', value: ''},
      {name: 'date', value: ''},
      {name: 'rideCount', value: ''},
      {name: 'level', value: ''},
      {name: 'email', value: ''},
    ]);

    /** set selected data to form */
    if (!isEmptyObject(modalState.data)) {
      form.setFieldsValue({...modalState.data, level: modalState.data.grade.grade});
    }
  }, [modalState, form]);

  const handleClickApply = async () => {
    const body = form.getFieldsValue();

    await updateUser(body.id, body)
      .then(r => {
        alert("수정 완료")
        setModalState({
          isVisible: false,
          data: {},
        })
        setForceReload();
        console.log(r)
      })
      .catch(e => {
        alert("에러")
        console.log(e)
      })
  }

  const ModalFooter = () => {
    return <>
      <Button type='default' onClick={hideModal}>{R.text.cancel}</Button>
      <Button form='userModifyForm' htmlType='submit' type='primary'>{R.text.apply}</Button>
    </>
  }

  return (
    <Modal title={'유저 정보 수정'}
           onCancel={() => setModalState({
             isVisible: false,
             data: {}
           })}
           visible={modalState.isVisible}
           footer={<ModalFooter/>}
           forceRender={true}>
      <Form onFinish={handleClickApply}
            id='userModifyForm'
            form={form}
            labelCol={{span: 6}}>
        <Row>
          <Col span={24}>
            <Card>
              <Form.Item name={"name"} label={`${R.text.name}`}>
                {modalState.data?.name}
              </Form.Item>
              <Form.Item name={"phoneNumber"} label={`${R.text.phone}`} >
                {modalState.data?.phoneNumber}
              </Form.Item>
              <Form.Item name={"externalId"} label={`${R.text.external_id}`}>
                {modalState.data?.externalId}
              </Form.Item>
              <Form.Item name={"sex"} label={`${R.text.gender}`}>
                {modalState.data?.sex === 1 ? "남" : "여"}
              </Form.Item>
              <Form.Item name={"birthday"} label={`${R.text.birthday}`}>
                {modalState.data?.birthday}
              </Form.Item>
              <Form.Item name={"date"} label={`${R.text.signup_at}`}>
                {modalState.data?.date}
              </Form.Item>
              <Form.Item name={"rideCount"} label={`${R.text.ride_count}`}>
                {modalState.data?.rideCount ? modalState.data?.rideCount + "회" : "0회"}
              </Form.Item>
              <Form.Item label={`${R.text.level}`}
                         name={'level'}>
                {modalState.data?.level}
              </Form.Item>
              <Form.Item label={`${R.text.email}`} name={'email'}>
                <Input
                  style={{width: "208px"}}
                  placeholder={'이메일을 입력해주세요.'}
                  allowClear/>
              </Form.Item>
              <Form.Item name={"id"}/>
            </Card>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default UserEditModal
