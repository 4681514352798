export const billCategories = [
  "Rent",
  "Subscription",
  "Penalty",
  "Deposit",
  "Fine",
  "Point",
  "GiftRent",
  "GiftPenalty",
  "MEMBERSHIP",
  "TAXI",
  "TAXI_CALL_FEE",
  "Other",
] as const;
export type BillCategoryType = (typeof billCategories)[number];

export const BillCategoryTranslate: Record<BillCategoryType, string> = {
  Rent: "대여",
  Subscription: "구독",
  Penalty: "패널티",
  Deposit: "보증금",
  Fine: "견인/과태료",
  Point: "포인트",
  GiftRent: "선물대여",
  GiftPenalty: "선물패널티",
  MEMBERSHIP: "멤버십",
  TAXI: "택시",
  TAXI_CALL_FEE: "택시 호출료",
  Other: "외 기타",
};
