import React from "react"
import { Button, Modal } from 'antd';
import { updateUser } from "../../users/services/users.service";

interface UserPhoneDeleteModalProps {
  open: boolean;
  onOpenChange: (isOpen: boolean) => void;
  userData: any;
  setForceReload: React.DispatchWithoutAction;
}

export const UserPhoneDeleteModal = ({
  onOpenChange,
  open,
  userData,
  setForceReload
}: UserPhoneDeleteModalProps) => {


  const closeModal = () => onOpenChange(false);

  const handleClickApply = async () => {
    const userId = userData.id;

    const body = {
      id: userId,
      name: userData.name,
      phoneNumber: null,
      externalId: userData.externalId,
      sex: userData.sex,
      birthday: userData.birthday,
      date: userData.date,
      rideCount: userData.rideCount,
      level: userData.grade.grade,
      email: userData.email,
    }

    await updateUser(userId, body)
      .then(r => {
        alert("삭제 완료")
        closeModal();
        setForceReload();
        console.log(r)
      })
      .catch(e => {
        alert("에러")
        console.log(e)
      })
  }

  const ModalFooter = () => {
    return <>
      <Button type='default' onClick={closeModal}>취소</Button>
      <Button onClick={handleClickApply} type='primary' danger>삭제하기</Button>
    </>
  }

  return (
    <Modal title={'전화번호 삭제'}
      onCancel={closeModal}
      visible={open}
      footer={<ModalFooter />}
      forceRender={true}>
      <div>
        <p>전화번호를 삭제하시겠습니까?</p>
      </div>
    </Modal>
  )
}